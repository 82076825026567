/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect,useState,CSSProperties } from "react";
import clsx from "clsx";
import {ITab, ITabs} from "../../types/customizer";
import { connect } from "react-redux";
import currency from "currency.js";
import { SYMBOLS } from "../../../../utils/Constants";
import './index.scss';
import LashbrookLogo from "../../../../assets/images/lashbrook.svg";

import { twMerge } from "tailwind-merge";
import { FONT_MAP, getSelectedInfo } from "../../utils/commonRingUtils";
import If from "../../../../components/Shared/If";
import { Link } from "react-router-dom";
import URLs from "../../../../utils/url";

interface Props {
	pages: ITabs;
	selectedTab: string;
	handleActiveTab: Function;
	price: string;
	handleDone: () => void;
}

const DesktopSidebar = ({
	pages,
	selectedTab,
	handleActiveTab,
	price,
	handleDone,
}: Props) => {
	console.log({pages})
	const [horizontalStyle, setHorizontalStyle] = useState<CSSProperties>({});
	const [verticalStyle, setVerticalStyle] = useState<CSSProperties>({});

	useEffect(() => {
		setHorizontalStyle(getHorizontalStyle());
		setVerticalStyle(getVerticalStyle());
	}, [selectedTab, pages])

	const getHorizontalStyle = ():CSSProperties => {
		const tabContentRect = document.getElementById('main-content')?.getBoundingClientRect();
		const sidebarRect = document.getElementById(selectedTab)?.getBoundingClientRect();
		if(tabContentRect && sidebarRect) {
			let tabcontentTop = tabContentRect.top;
			const sidebarTopX = sidebarRect.top ;
			const sidebarBottomX = sidebarRect.top  + sidebarRect.height;

			const selectedPage = pages.find(item => item.eventKey === selectedTab);
			const hasItems =  selectedPage?.items?.length || 0;

			tabcontentTop = tabcontentTop + (hasItems ? 47 : 2);

			if (tabcontentTop - sidebarTopX > 20 && tabcontentTop - sidebarBottomX > 20) { // If side tab above 20px of margin line
				return {
					bottom: '0px',
					top: 'unset',
				}
			} else if(sidebarTopX - tabcontentTop > 20 && sidebarBottomX  - tabcontentTop > 20) { // If side tab below 20px of margin line
				return {
					top: '0px',
					bottom: 'unset'
				}
			} else if(tabcontentTop - sidebarTopX > 20 ) { // If sidebar top above 20px of margin line
				return {
					top: '0px',
					bottom: 'unset'
				}
			} else  {
				return {
					bottom: '0px',
					top: 'unset',
				}
			}
		}
		return {}
	}

	const getVerticalStyle = ():CSSProperties => {
		const tabContentRect = document.getElementById('main-content')?.getBoundingClientRect();
		const sidebarRect = document.getElementById(selectedTab)?.getBoundingClientRect();
		if(tabContentRect && sidebarRect) {
			let tabcontentTop = tabContentRect.top;
			const sidebarTopX = sidebarRect.top ;
			const sidebarBottomX = sidebarRect.top  + sidebarRect.height;

			const selectedPage = pages.find(item => item.eventKey === selectedTab);
			const hasItems =  selectedPage?.items?.length || 0;

			tabcontentTop = tabcontentTop + (hasItems ? 47 : 2);

			let height = 0;
			if (tabcontentTop - sidebarTopX > 20 && tabcontentTop - sidebarBottomX > 20) { // If side tab above 20px of margin line
				height = tabcontentTop - sidebarBottomX;
				return {
					height: height + 'px',
					top: '100%',
					bottom: 'unset'
				}
			} else if(sidebarTopX - tabcontentTop > 20 && sidebarBottomX  - tabcontentTop > 20) { // If side tab below 20px of margin line
				height = sidebarTopX - tabcontentTop;
				return {
					height: height + 'px',
					bottom: '100%',
					top: 'unset'
				}
			} else if(tabcontentTop - sidebarTopX > 20) { // If sidebar top above 20px of margin line
				height = tabcontentTop - sidebarTopX;
				return {
					height: height + 'px',
					top: '0px',
					bottom: 'unset',
				}
			} else {
				height = sidebarBottomX - tabcontentTop;
				return {
					height: height + 'px',
					top: 'unset',
					bottom: '0px',
				}
			}
		}
		return {}
	}

	const getUrl = (swatch_url: string) => {
		return swatch_url.indexOf('http') !== -1 ? swatch_url : `${URLs.assetsBaseUrl}${swatch_url}`
}


	// Hide for xs devices
	return (
		<div className="tw-hidden sm:tw-flex tw-bg-alice-blue desktop-sidebar tw-p-3 tw-pt-2 tw-pr-0 tw-w-full tw-h-full sm:tw-w-[260px]  lg:tw-w-[300px] xl:tw-w-[320px]">
			<div className="tw-flex tw-flex-col tw-justify-between tw-w-full">
				<div className="tw-flex tw-flex-col tw-w-full">
					<Link to='/'>
				      <img src={LashbrookLogo} alt="Lashbrook" className="tw-w-[105px] tw-mb-7 tw-mt-4" />
					</Link>
					{pages.map(item => {
						const selectedInfo = getSelectedInfo(item);
						const selectedText = selectedInfo?.text || '';
						return (
							<div
								id={item.eventKey}
								key={item.eventKey}
								className={clsx(`tw-flex tw-items-center tw-justify-between lg:tw-text-base tw-cursor-pointer tw-pl-3 tw-py-2 tw-border-gray-500 sidebar-tab tw-border-solid`,
								{'tw-bg-white tw-pr-4 active': selectedTab === item.eventKey},
								{'tw-border-t tw-mr-4': selectedTab !== item.eventKey})}
								onClick={handleActiveTab?.(item.eventKey, false)}>
								<div className="tw-flex tw-items-start tw-justify-center tw-flex-col tab-text">
									{/* Sidebar title starts */}
									<p
										className={'tw-relative tw-font-medium sidebar-title primary-font-family'}>
										{typeof item.title === 'string' && item.title}
										{typeof item.title === 'function' && item.title()}
									</p>
									{/* Sidebar title ends */}

									{/* Sub content starts */}
										{item?.disclaimer && <p className="tw-text-xs tw-mt-2 tw-text-blue-900 tw-text-opacity-80">{item?.disclaimer}</p>}
										{/* For engraving alone */}
										<If condition={item.eventKey === 'engraving' && item.selectedItem === 'text'}>
											<p className={twMerge("tw-text-xs tw-mt-2", FONT_MAP[selectedText || ''])}>
												{selectedInfo?.engravingText || '(none)'}
											</p>
										</If>
										{/* For outside and 2nd outside alone */}
										{item.selectedFeature && <p className="tw-text-xs tw-mt-2">{item.selectedFeature}</p>}
									{/* Sub content ends */}
								</div>
								<div className={clsx("tw-flex tw-justify-center tw-items-center tw-flex-col tw-w-24", {'tw-relative': selectedTab === item.eventKey})}>
									{selectedInfo?.url && !(/^None$/i).test(selectedText) &&
									<img className="tw-rounded-full tw-h-7 tw-w-7 tw-mb-1" alt="img"
										src={`${getUrl(selectedInfo?.url)}`}/>}
									{selectedText && <p className="tw-text-xs tw-text-center tw-text-blue-900 tw-text-opacity-80">{selectedText}</p>}
									<If condition={item.eventKey === 'summary'}>
										<p className='tw-text-lg tw-text-center'>
										{price}
										</p>
									</If>
								</div>
								<If condition={selectedTab === item.eventKey}>
									<div id="active-h-border" style={horizontalStyle}></div>
									<div id="active-v-border" style={verticalStyle}></div>
								</If>
							</div>
						)
					})}
				</div>

				<div className='tw-flex tw-items-center tw-justify-center tw-w-full tw-pr-5 tw-py-3'>
					<button className='tw-bg-blue-500 hover:tw-bg-blue-500 tw-text-white hover:tw-text-white tw-w-full tw-max-w-[191px] tw-px-9 tw-py-2 tw-rounded-full tw-font-medium'
						onClick={handleDone}>Done</button>
				</div>
			</div>
		</div>
	)
}

export default DesktopSidebar;
