import { IOption } from "../types/customizer";
import weddingBand from '../../../assets/images/wedding-bands.png';
import anniversary from '../../../assets/images/anniversary.jpg';

import signet from '../../../assets/images/signet.png';
import collegiateSignets from '../../../assets/images/collegiate-signets.png';
import collegiateBand from '../../../assets/images/collegiate-band.png';
import collegiateSleeve from '../../../assets/images/collegiate-sleeve.png';

export const isEmpty = (value: any) => value === null || value === undefined || value === '' ||
                        (Array.isArray(value) && value.length === 0);

export const defaultRingUrls = {
  'band': '/rings/profiles/D/widths/7/ring_materials/14KW?ring_finish=SATIN&finish_type=F&weight=P&size=10&feature_group=N&sleeve=NONE',
  'womens': '/women/rings/styles/010/ring_materials/14KW/color_arrangements/B/stone_quantities/HALFETERNITY/variants/2?size=7&dia=LABDIA-ST',
  'collegiate_signet': '/rings/collegiate_signet/ring_materials/14KY?ring_finish=POLISH,POLISH,&shape=CIRC&size=SM&shank=T&school=BYU&signet_engraving=BYU2&cerakote_color=NONE&finger_size=10&weight=S',
  'signet': '/rings/signet/ring_materials/14KW?ring_finish=POLISH,POLISH,&shape=CIRC&size=SM&shank=T&finger_size=10&feature_group=N&weight=S'
};

export const getCategorizedOptions = (sku: string) => {
  const list: IOption[] = [
    {
      name: 'Band',
      type: 'option',
      swatch_url: weddingBand,
      selected: false,
      sku: 'band',
    },
    {
      name: 'Anniversary',
      type: 'option',
      swatch_url: anniversary,
      selected: false,
      sku: 'womens',
    },
    {
      name: 'Signet',
      type: 'option',
      swatch_url: signet,
      selected: false,
      sku: 'signet',
    },
    {
      name: 'Collegiate',
      type: 'option',
      swatch_url: collegiateSignets,
      selected: false,
      sku: 'collegiate_signet',
    },
  ];
  return list.map((item) => {
    if (item.sku === sku) {
      item.selected = true;
    }
    return item;
  });
}

export const getTypeOptions = (sku: string) => {
  if(sku === 'signet') {
    return [];
  } else {
    const list: IOption[] = [
      {
        name: 'Collegiate Signet',
        type: 'option',
        swatch_url: collegiateSignets,
        selected: false,
        sku: 'collegiate_signet',
      },
      // {
      //   name: 'Collegiate Band',
      //   type: 'option',
      //   swatch_url: collegiateBand,
      //   selected: false,
      //   sku: 'collegiate_band',
      // },
      // {
      //   name: 'Collegiate Sleeve',
      //   type: 'option',
      //   swatch_url: collegiateSleeve,
      //   selected: false,
      //   sku: 'collegiate_sleeve',
      // },
    ];
    return list.map((item) => {
      if (item.sku === sku) {
        item.selected = true;
      }
      return item;
    });
  }

}
