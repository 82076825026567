import SwatchFilter from '../components/SwatchFilter';
import { ITab, ITabs } from '../types/customizer';
import { getFilterMetaData, getSelectedOption, getWarning } from './commonRingUtils';

export const getArrangementItems = (product: any) => {
    const items: ITabs = [
        {
            title: 'Stone Quantity',
            label: 'Stone Quantity',
            eventKey: 'stoneQuality',
            component: SwatchFilter,
            isEnabled: product.stone_quantity?.length > 0,
            content: {
                // showDefaultTitle: true,
                // displayShowMoreOption: false,
                // meta: getFilterMetaData(product, 'stone_quantity'),
                // warning: getWarning(product, 'stone_quantity'),
                hasSwatch: false,
                fieldType: 'stone_quantities',
                options: product.stone_quantity || [],
                selectedOption: getSelectedOption(product.stone_quantity),
            },
        },
        {
            title: 'Carat Weight',
            label: 'Ring Total Carat Weight',
            eventKey: 'caratWeight',
            component: SwatchFilter,
            isEnabled: product.variant?.length > 0,
            content: {
                // showDefaultTitle: true,
                // displayShowMoreOption: false,
                // meta: getFilterMetaData(product, 'variant'),
                // warning: getWarning(product, 'variant'),
                hasSwatch: false,
                fieldType: 'variants',
                options: product.variant || [],
                selectedOption: getSelectedOption(product.variant),
            },
        },
        {
            title: 'Color Arrangement',
            label: 'Color Arrangement',
            eventKey: 'colorArrangement',
            component: SwatchFilter,
            isEnabled: product.color_arrangement?.length > 0,
            content: {
                // showDefaultTitle: true,
                // displayShowMoreOption: false,
                // meta: getFilterMetaData(product, 'color_arrangement'),
                // warning: getWarning(product, 'color_arrangement'),
                hasSwatch: false,
                fieldType: 'color_arrangements',
                options: product.color_arrangement || [],
                selectedOption: getSelectedOption(product.color_arrangement),
            },
        },
        {
            title: 'Color',
            label: 'Gemstone Type',
            eventKey: 'gemstone_1',
            component: SwatchFilter,
            isEnabled: product.gemstone?.gemstone_1?.gemstone_material?.length > 0,
            content: {
                // showDefaultTitle: true,
                // displayShowMoreOption: false,
                // meta: getFilterMetaData(product, 'gemstone_material'),
                // warning: getWarning(product, 'gemstone_material'),
                hasSwatch: true,
                fieldType: 'gemstone_details_1',
                options: product.gemstone?.gemstone_1?.gemstone_material || [],
                selectedOption: getSelectedOption(product.gemstone?.gemstone_1?.gemstone_material),
            },
        },
        {
            title: 'Color 2',
            label: 'Second Gemstone Type',
            eventKey: 'gemstone_2',
            component: SwatchFilter,
            isEnabled: product.gemstone?.gemstone_2?.gemstone_material?.length > 0,
            content: {
                // showDefaultTitle: true,
                // displayShowMoreOption: false,
                // meta: getFilterMetaData(product, 'gemstone_material'),
                // warning: getWarning(product, 'gemstone_material'),
                hasSwatch: true,
                fieldType: 'gemstone_details_2',
                options: product.gemstone?.gemstone_2?.gemstone_material,
                selectedOption: getSelectedOption(product.gemstone?.gemstone_2?.gemstone_material),
            },
        },
        {
            title: 'Color 3',
            eventKey: 'gemstone_3',
            label: 'Third Gemstone Type',
            component: SwatchFilter,
            isEnabled: product.gemstone?.gemstone_3?.gemstone_material?.length > 0,
            content: {
                // showDefaultTitle: true,
                // displayShowMoreOption: false,
                // meta: getFilterMetaData(product, 'gemstone_material'),
                // warning: getWarning(product, 'gemstone_material'),
                hasSwatch: true,
                fieldType: 'gemstone_details_3',
                options: product.gemstone?.gemstone_3?.gemstone_material,
                selectedOption: getSelectedOption(product.gemstone?.gemstone_3?.gemstone_material),
            },
        },
    ];
    const filtered = items.filter((tab: ITab) => tab.isEnabled || tab.items?.length);
    return filtered;
};
