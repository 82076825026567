/* eslint-disable react/jsx-indent */
import React from 'react';
import { Col, Row } from 'reactstrap';

import './sameDay.scss';

const SameDay = () => (
    <div className="same-day-container secondary-font-family">
        <img
            src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/landingPages/mystylebannera.png"
            alt="Crafting custom mens rings out of exotic materials since 2000. Made to order in the USA.
          We supply to customers throughout the US and Canada"
        />
        <div className="wrapper">
            <h1 className="text-center heading primary-font-family">
                Over 40,000<br />custom precious metal bands<br />shipped the same day.
            </h1>
        </div>
        <img
            src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2020/08/06145747/goldline.jpg"
            alt="gold-line"
            width="100%"
            className="img-center"
        />
        <div className="wrapper">
            <h1 className="subtitle text-center primary-font-family">GUARANTEED SAME-DAY SHIPPING*</h1>
            <Row>
                <Col sm={6}>
                    <p className="description pr-3">
                        Order a qualifying precious metal band by 2 PM EST, we’ll handcraft it and ship it the same day.
                        Every band is still made to order and guaranteed for life.
                        <br />
                        <br />
                        10K, 14K, & 18K White, Yellow, & Rose Gold, & Platinum
                        <br />
                        <br />
                        Any profile, finish, width, and weight combination from the My Style Collection
                        <br />
                        <br />
                        Any size up to 13.5
                        <br />
                        <br />
                        Even Inside engravings
                        <br />
                        <br />
                        *Lashbrook guarantees same-day shipping on qualifying precious metal purchases. If for any reason we are
                        unable to ship the same day we will cover the cost to overnight ship your order. Orders which do ship out the same day as
                        expected will ship via the method you select, and the shipping cost `is covered by you.
                    </p>
                </Col>
                <Col>
                    <img
                        src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2020/08/06143815/threerings-min.png"
                        alt="threerings"
                    />
                </Col>
            </Row>
        </div>
        <img
            src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2020/08/06145747/goldline.jpg"
            alt="gold-line"
            className="img-center"
        />
        <div className="wrapper">
            <h1 className="subtitle text-center primary-font-family">THE MY STYLE COLLECTION</h1>
            <Row>
                <Col sm={6}>
                    <img
                        src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/landingPages/My%20Style%20Kit_2024.png"
                        alt="threerings"
                    />
                </Col>
                <Col sm={6}>
                    <p>
                        Show the endless possibilities of custom in 60 seconds with the My Style Collection. Your
                        customers can easily mix-and-match aspects of various rings including metal, profile, width,
                        weight, finish, and engraving.
                        <br />
                        <br />
                        Need a My Style?<br />
                        888-252-7388<br />
                        info@lashbrookdesigns.com
                    </p>
                </Col>
            </Row>
        </div>
        <img
            src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2020/08/06145747/goldline.jpg"
            alt="gold-line"
            className="img-center"
        />
        <img
            src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2020/08/06145747/goldline.jpg"
            alt="gold-line"
            className="img-center"
        />
        <div className="wrapper">
            <h1 className="subtitle text-center primary-font-family">20 YEARS OF CUSTOM</h1>
            <Row>
                <Col>
                    <img
                        src="https://www.lashbrookdesigns.com/static/media/handcrafted_here.4b831bd6.png"
                        alt="PRODUCTION_IMAGES"
                    />
                </Col>
                <Col sm={6}>
                    <p className="description pr-3">
                        For over 20 years, our artisans have handcrafted every ring, one at a time, to fit the
                        uniqueness of each person we make them for. Starting as a solid block of metal, we use only the
                        highest quality materials. Our bands with same-day shipping are made from the same high-quality
                        material we use in every precious metal band. As always, every Lashbrook band is guaranteed for
                        life.
                    </p>
                </Col>
            </Row>
        </div>
    </div>
);

export default SameDay;
